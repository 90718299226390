/* Add these styles to your CSS file */

.about-us-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin: 20px;
    padding: 20px; */
    border: 1px solid #ccc;
    position: relative;
    background: url('../assets/slider/epscGF1G0TMuzoRn7PPKe-transformed-transformed.png') center/cover no-repeat;
    background-color: rgba(17, 17, 17, 0.4);
    overflow: hidden;
    /* Replace with your image path */
}

.about-us-section::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.73);
    /* Adjust the alpha value for transparency */
    z-index: 0;
}

.about-us-content {
    flex: 1;
    /* background: rgba(255, 255, 255, 0.6); */
    /* Adjust the alpha value for transparency */
    color: white;
    padding: 5% 20px 0 20px;
    margin: 2% 7%;
    border-radius: 8px;
    position: relative;
    z-index: 1;
}


/* Rest of the existing styles... */

.about-us-content h2 {
    font-size: 24px;
    color: #ffcc00;
}

.about-us-content p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.about-us-content button {
    /* background-color: #007bff; */
    background-color: #00854b;
    color: #fff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.about-us-content button:hover {
    /* background-color: #0056b3; */
    background-color: #006b3d;
}

.slider-container {
    width: 40%;
    margin: 7% 3% 7% 0;
    position: relative;
    z-index: 1;
}

.slick-prev,
.slick-next {
    font-size: 24px;
    color: #007bff;
}

.slick-prev:hover,
.slick-next:hover {
    color: #0056b3;
    background-color: #BE423D;
}

.slick-dots li button {
    font-size: 14px;
    color: #ffeaea;
}

.slick-dots li.slick-active button {
    color: #ffffff;
}

@media (max-width: 768px) {
    .about-us-section {
        flex-direction: column;
        background: url('../assets/slider/epscGF1G0TMuzoRn7PPKe-transformed_mobile.png') center/cover no-repeat;
    }
    .about-us-content {
        margin-top: 20px;
    }
    .slider-container {
        width: 86%;
        margin: 7%;
    }
}