.g-container {
    margin: 0% 5%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* Allow items to wrap to the next line on smaller screens */
    margin-bottom: 50px;
}

.g-section1 {
    width: 60%;
    text-align: center;
    margin: 2%;
}

.g-section2 {
    width: 32%;
    margin: 2%;
}

.gallery-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.photo-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1px;
    background-color: rgba(255, 255, 255, 0.4);
    /* Use RGBA for transparency */
    border: 2px solid black;
    position: relative;
    z-index: 0;
}

.photo-gallery::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    /* Adjust the alpha value for transparency */
    z-index: 0;
}

.button-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.visit-button {
    /* position: absolute;
    top: 50%; */
    /* margin-top: 20px; */
    /* Adjust margin-top as needed */
    padding: 10px 20px;
    font-size: 18px;
    /* background-color: #007bff; */
    background-color: #00854b;
    /* Adjust background color as needed */
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    z-index: 3;
}

.gallery-item-container {
    width: 80px;
    height: 80px;
    position: relative;
    overflow: hidden;
}

.gallery-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.quick-links {
    /* Adjust the width based on your layout */
    padding: 20px;
    background-color: rgba(255, 255, 255);
    text-align: left;
    border-radius: 10px;
    border: 1px solid rgb(186, 186, 186);
    box-shadow: 2px 2px 2px gray;
    /* text-decoration: underline; */
    /* Add a background color for the quick links section */
}

.quick-links h2 {
    text-align: center;
}

.quick-links a {
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 550;
    /* color: #333; */
    color: rgb(0, 7, 103);
    /* Adjust the color based on your design */
}

.quick-links a:hover {
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 550;
    /* color: #333; */
    color: #ffcc00;
    /* Adjust the color based on your design */
}

@media(max-width:1048px) {
    .g-section1 {
        width: 100%;
        margin-bottom: 30px;
    }
    .g-section2 {
        width: 100%;
    }
}