/* TopBar.css */

.top-bar {
    background-color: #00854b;
    /* background-color: rgba(0, 0, 0, 0.792);
    background-image: linear-gradient(to right, #00c26ece, #00c26ece); */
    /* color: #fff; */
    padding: 10px;
    display: flex;
    justify-content: flex-end;
}