.file-table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .file-table {
    border-collapse: collapse;
    width: 80%;
  }
  
  .file-table th, .file-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .file-table th {
    background-color: #f2f2f2;
  }
  