.contact-container {
    max-width: 600px;
    margin: 5% auto;
    padding: 20px;
    /* background-color: #333333;
    color: white; */
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
}

.address-section,
.phone-section,
.email-section {
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .contact-container {
        display: flex;
        justify-content: space-between;
    }
    .address-section,
    .phone-section,
    .email-section {
        width: 30%;
    }
}