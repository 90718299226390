.navbar-second {
    position: relative;
    top: 0%;
    right: 0%;
    left: 0%;
    background: rgb(255, 255, 255);
    /* height: 85px; */
    padding: 0;
    display: flex;
    justify-content: space-between;
    z-index: 1000;
}


/* x-100
y-0-10 normal v 00-1 */

.navbar-second .nav-side-menu {
    display: grid;
    grid-template-columns: repeat(9, auto);
    /* gap: 0 10px; */
    /* width: 70vw; */
    justify-content: end;
    margin: 0%;
    /* margin-right: 35px; */
}

.navbar-second .nav-items {
    display: flex;
    align-items: center;
    /* height: 80px; */
}

.navbar-second .nav-links {
    color: rgb(0, 0, 0);
    text-decoration: none;
    padding: 8px 20px;
    transition: all 0.3s ease-out;
}

.navbar-second .nav-links:hover {
    background-color: #000000;
    border-radius: 5px;
    color: #fff;
}

.navbar-second .fa-bars {
    color: #000000;
}

.navbar-second .nav-links-button {
    display: none;
}

.navbar-second .menu-icon {
    display: none;
}

@media screen and (max-width: 1000px) {
    .navbar-second {
        height: 75px;
    }
    .navbar-second .nav-side-menu {
        display: flex;
        flex-direction: column;
        width: 250px;
        /* height: calc(100vh - 85px); */
        height: auto;
        position: absolute;
        margin-top: 0;
        top: 85px;
        border: 2px solid #ececee;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        justify-content: flex-start;
        /* z-index: 5; */
    }
    .navbar-second .nav-side-menu.start {
        background: #fff;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
    }
    .navbar-second .nav-links {
        text-align: center;
        padding: 1.5rem;
        width: 100%;
        color: #000;
        display: table;
        transition: 0s;
    }
    .navbar-second .nav-links:hover {
        color: #fff;
    }
    .navbar-second .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
    .navbar-second .fa-times {
        color: #000000;
        font-size: 2rem;
    }
    .navbar-second .nav-links-button {
        display: block;
        padding: 12px 30px;
        margin: 25px auto;
        border-radius: 10px;
        background: #000;
        text-decoration: none;
        color: #fff;
        font-size: 1.3rem;
    }
    .navbar-second .nav-items {
        height: auto;
    }
}