    /* Navbar.css */
    
    .NavBar-container {
        width: 100%;
    }
    
    .navbar {
        display: flex;
        justify-content: space-between;
        padding: 10px 6% 10px 6%;
        background-color: #fffefe;
        color: #000000;
    }
    
    .logo-right-section {
        width: 92%;
    }
    
    .left-section {
        display: flex;
        justify-content: space-between;
        padding: 20px;
    }
    
    .head-section {
        text-align: left;
    }
    
    .institute-name2 {
        font-family: Calibri;
        margin-left: 20px;
        font-size: 1.6rem;
        font-weight: 600;
        display: block;
        padding-bottom: 8px;
        text-shadow: 2px 2px 2px rgba(93, 93, 93, 0.5);
    }
    
    .institute-name3 {
        font-family: Calibri;
        /* padding: 10px; */
        margin-left: 20px;
        font-size: 1.3rem;
        font-weight: 550;
        display: block;
        padding-bottom: 8px;
    }
    
    .institute-name4 {
        font-family: Calibri;
        font-weight: bold;
        margin-left: 20px;
        font-size: 1.2rem;
        display: block;
    }
    
    .right-section {
        margin-right: 30px;
    }
    
    .right-section button {
        background-color: #00854b;
        /* background-color: rgba(0, 0, 0, 0.792);
        background-image: linear-gradient(to right, #00c26ece, #00c26ece); */
        color: #FDF7F0;
        margin-top: 30px;
        padding: 10px 20px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
    }
    
    .right-section button:hover {
        background-color: #006c3d;
        /* background-color: rgba(0, 0, 0, 0.792);
        background-image: linear-gradient(to right, #00854bd6, #00854bd6); */
        color: #ffffff;
    }
    /* Media query for mobile view */
    /* Add this at the end of your existing CSS file */
    /* Media query for mobile view */
    
    @media only screen and (max-width: 768px) {
        .navbar {
            flex-direction: column;
            align-items: center;
            padding: 10px 0%;
        }
        .logo-right-section {
            width: 100%;
        }
        .head-section {
            text-align: center;
        }
        .left-section {
            flex-direction: column;
            align-items: center;
            padding: 20px;
        }
        .institute-name {
            margin-left: 0;
            margin-bottom: 10px;
        }
        .institute-name2 {
            margin-left: 0px;
        }
        .institute-name3 {
            margin-left: 0px;
        }
        .institute-name4 {
            margin-left: 0px;
        }
        .right-section {
            margin-right: 0;
        }
        .right-section button {
            margin-top: 10px;
        }
    }