.g-container_1 {
    margin: 0% 5%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* Allow items to wrap to the next line on smaller screens */
    margin-bottom: 50px;
}

.g-section1_1 {
    width: 100%;
    text-align: center;
    margin: 2%;
}

.gallery-container_1 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.photo-gallery_1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1px;
    /* background-color: rgba(255, 255, 255, 0.4); */
    /* Use RGBA for transparency */
    border: 2px solid black;
    /* position: relative; */
    /* z-index: 0; */
}

.photo-gallery_1::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(237, 237, 237);
    z-index: -1;
}

.gallery-item-container_1 {
    width: 180px;
    height: 180px;
    position: relative;
    overflow: hidden;
    margin: 2px;
}

.gallery-item_1 {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gallery-item_1:hover {
    position: relative;
    transform: translateY(-5px);
}


/* Add these styles at the end of your existing CSS file */

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
}

.modal-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media(max-width:1048px) {
    .g-section1_1 {
        width: 100%;
        margin-bottom: 30px;
    }
}