.facility-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
}

h2 {
    font-size: 28px;
}

h3 {
    margin: 0%;
    padding: 0%;
}

.p {
    font-size: 16px;
    margin-bottom: 20px;
}

.facility-sections {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 20%;
}

.facility-container-in {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.facility-img img {
    width: 250px;
    height: auto;
}

.facility-card {
    width: 210px;
    height: 180px;
    padding: 4px;
    text-align: center;
    margin-top: 10px;
    border: 1px solid black;
    background-color: #ffffff;
    position: relative;
    top: -60px;
}

.facility-card .f-icon {
    font-size: 30px;
    margin: 5px;
}

.facility-card p {
    font-size: 14px;
}

button {
    /* background-color: #007bff; */
    /* background-color: #68005a; */
    background-color: #00854b;
    color: #fff;
    border: none;
    border-radius: 25px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    /* background-color: #0056b3; */
    /* background-color: #530048; */
    background-color: #006b3d;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in {
    animation: fadeIn 1s ease-in-out;
    /* Adjust the duration and timing function as needed */
}