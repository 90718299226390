/************************
         Footer
************************/

footer {
    width: 100%;
    margin-top: 50px;
    /* background-color: rgba(230, 230, 230, 0.853); */
    background-color: whitesmoke;
    border: 0.5px solid rgb(226, 224, 224);
    border-style: solid none none none;
}

.container-footer {
    padding: 50px 150px 50px 150px;
    margin: auto;
    display: flex;
    color: rgb(63, 62, 62);
}

.footer-sections {
    width: 50%;
    display: flex;
    margin: auto;
    justify-content: space-between;
}

.footer-containers {
    /* justify-content: space-between; */
    margin: auto;
}

.footer-inner {
    text-align: center;
}

.footer-inner h4 {
    padding: 2px 2px 10px 2px;
}

.footer-inner img {
    width: 80px;
    height: 80px;
}

.social-icons {
    padding: 4px;
}

.social-icons a {
    padding: 0 10px 0 10px;
    color: rgb(87, 82, 82);
}

.footer-container-contents {
    text-align: left;
}

.container-footer ul {
    padding: 15px;
}

.container-footer ul li {
    list-style: none;
    padding: 5px 0px;
}

.container-footer ul li a {
    color: rgb(87, 82, 82);
}

.container-footer ul li a:hover {
    color: #ffcc00;
}

.footer1 {
    padding: 12px;
    text-align: center;
    background-color: #00854b;
}

.footer1 p {
    color: white;
}

@media(max-width:1048px) {
    .container-footer {
        display: block;
    }
    .container-footer ul {
        padding: 0px;
    }
    .footer-sections {
        width: 100%;
        display: flex;
        margin: auto;
        justify-content: space-between;
    }
    .footer-containers {
        justify-content: space-between;
        margin: 20px;
    }
    .footer-inner {
        justify-content: center;
    }
}

@media (max-width: 600px) {
    .container-footer {
        display: block;
        justify-content: center;
        text-align: center;
        padding: 0 0 20px 0;
        margin: 0;
    }
    .container-footer ul {
        padding: 0px;
    }
    .footer-sections {
        width: 100%;
        display: block;
        margin: 0;
        padding: 0;
        justify-content: space-between;
    }
    .footer-containers {
        justify-content: center;
        padding-top: 10px;
    }
    .footer-container-contents {
        text-align: center;
        /* Update to center the text */
        padding-left: 0;
        /* Update to remove unnecessary padding */
    }
}