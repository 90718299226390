.annex_list {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin: 5%;
    padding: 2%;
    position: relative;
    justify-content: space-around;
    border: 1px solid gray;
    border-radius: 10px;
}

.muhs_content {
    width: 30%;
}

.annexure-title {
    color: #00854b;
}

.annexdetails {
    color: whitesmoke;
}

.year-list {
    background-color: #333333;
    border-radius: 10px;
    margin: 8% 2%;
    padding: 2%;
    color: #ffffff;
}

.year-list ul {
    padding: 0px;
}

.year-list li {
    padding: 8px;
    list-style: none;
}

.year-list li:hover {
    background-color: #00854b;
    border-radius: 3px;
}

.annexure-details-container {
    background-color: #333333;
    border-radius: 10px;
    margin: 8% 2%;
    padding: 2%;
}

.list_annex_content ul {
    padding: 0px;
}

.list_annex_content li {
    padding: 8px;
}

.list_annex_content li:hover {
    color: #ffffff;
    background-color: #333333;
    border-radius: 3px;
}

.list_annex_content li:hover::after {
    content: '\1F4DD';
}

.list_annex_list {
    list-style: none;
}

.file_annex_list ul {
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.file_annex_list li {
    list-style: none;
    padding: 8px;
}

.file_annex_list li:hover {
    background-color: #00854b;
    border-radius: 3px;
}

.file_annex_list li:hover::after {
    content: '\1F4DD';
}

.annexure-title:hover::before {
    /* content: '\1F4DD'; */
    /* Unicode for a file icon, you can use a different icon */
    position: relative;
    top: 50%;
    right: 0.5em;
    transform: translateY(-50%);
}

@media screen and (max-width: 1000px) {
    .annex_list {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        margin: 5%;
        padding: 2%;
        position: relative;
        justify-content: space-around;
        border: 1px solid gray;
        border-radius: 10px;
    }
    .muhs_content {
        width: 100%;
    }
}